import { Campaign } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import CheckBox from 'components/shared/CheckBox';
import { CustomTooltipSmall } from 'components/shared/CustomTooltip';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UserSessionSelector } from 'store/UserSession';
import { PIXLINK_CAMPAIGN_STATUS, PIXLINK_ROLES, PIXLINK_STATUS } from 'utils/Constants';
import { isIdInArray } from 'utils/tables/tables';

export function SuperAdminSearchSpotTable({
  shownNetlinkingFilteredList,
  nameSearch,
  onNetlinkingContactFilterChange,
  contactFilter,
  sendNetlinkingExport,
  ordersPerPage,
  sendNetlinkingChoose,
  netlinikingToChoseData,
  netlinkingChosenData,
  wantedNetlinkings,
  historicBackLinkings,
  columns,
  selectedStatus,
  sendLengthArrayShown,
  campaignStatus,
  reactivateSelection
}) {
  const { t } = useTranslation();
  let { user } = useSelector(UserSessionSelector);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = ordersPerPage || 20;
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortedItems, setSortedItems] = useState([]);
  const [statusSortedItems, setstatusSortedItems] = useState(null);
  const [tableExport, setTableExport] = useState([]);
  const [tableChoose, setTableChoose] = useState([]);
  const [mergedNetlinkings, setMergedNetlinkings] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (selectedStatus?.includes('')) {
      sendLengthArrayShown(
        mergedNetlinkings.filter((item) =>
          item.domain.toLowerCase().includes(nameSearch.toLowerCase())
        ).length
      );
    } else {
      sendLengthArrayShown(
        statusSortedItems !== null
          ? statusSortedItems.filter((item) =>
            item.domain.toLowerCase().includes(nameSearch.toLowerCase())
          ).length
          : sortedItems.filter((item) =>
            item.domain.toLowerCase().includes(nameSearch.toLowerCase())
          ).length
      );
    }
  }, [
    statusSortedItems,
    shownNetlinkingFilteredList,
    nameSearch,
    mergedNetlinkings,
  ]);

  const filterStatusMenuList = [
    { text: t('Tous les status'), status: '' },
    { text: t('A définir'), status: PIXLINK_STATUS.TO_DEFINE },
    {
      text: t('A valider Pixalione'),
      status: PIXLINK_STATUS.TO_BE_VALIDATED_PIXALIONE,
    },
    {
      text: t('A valider client'),
      status: PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT,
    },
    {
      text: t('Validé client'),
      status: PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT,
    },
    { text: t('A commander'), status: PIXLINK_STATUS.TO_BE_COMMANDED },
    { text: t('Refusé client'), status: PIXLINK_STATUS.REFUSED_CLIENT },
    { text: t('Refusé Pixalione'), status: PIXLINK_STATUS.REFUSED_PIXALIONE },
  ];

  useEffect(() => {
    setTableChoose(netlinikingToChoseData);
  }, []);

  useEffect(() => {
    const sorted = [...mergedNetlinkings].sort((a, b) => {
      if (sortColumn === 'domain') {
        return sortDirection === 'asc'
          ? a.domain.localeCompare(b.domain)
          : b.domain.localeCompare(a.domain);
      } else if (sortColumn === 'languageId') {
        return sortDirection === 'asc'
          ? a.languageId - b.languageId
          : b.languageId - a.languageId;
      } else if (sortColumn === 'themesList') {
        return sortDirection === 'asc'
          ? (a.themesList || '').localeCompare(b.themesList || '')
          : (b.themesList || '').localeCompare(a.themesList || '');
      } else if (sortColumn === 'topicsList') {
        return sortDirection === 'asc'
          ? (a.topicsList || '').localeCompare(b.topicsList || '')
          : (b.topicsList || '').localeCompare(a.topicsList || '');
      } else if (sortColumn === 'contact') {
        return sortDirection === 'asc'
          ? (getEmailPart(a.contact) || '').localeCompare(
            getEmailPart(b.contact) || ''
          )
          : (getEmailPart(b.contact) || '').localeCompare(
            getEmailPart(a.contact) || ''
          );
      } else if (sortColumn === 'contactName') {
        return sortDirection === 'asc'
          ? (getNamePart(a.contact) || '').localeCompare(
            getNamePart(b.contact) || ''
          )
          : (getNamePart(b.contact) || '').localeCompare(
            getNamePart(a.contact) || ''
          );
      } else if (sortColumn === 'contactedAt') {
        return sortDirection === 'asc'
          ? (a.contactedTime || '').localeCompare(b.contactedTime || '')
          : (b.contactedTime || '').localeCompare(a.contactedTime || '');
      } else if (sortColumn === 'kwPosition') {
        return sortDirection === 'asc'
          ? (a.kwPositioned || 0) - (b.kwPositioned || 0)
          : (b.kwPositioned || 0) - (a.kwPositioned || 0);
      } else if (sortColumn === 'Trafic') {
        return sortDirection === 'asc'
          ? (a.trafic || 0) - (b.trafic || 0)
          : (b.trafic || 0) - (a.trafic || 0);
      } else if (sortColumn === 'tF') {
        return sortDirection === 'asc'
          ? (a.tF || 0) - (b.tF || 0)
          : (b.tF || 0) - (a.tF || 0);
      } else if (sortColumn === 'cF') {
        return sortDirection === 'asc'
          ? (a.cF || 0) - (b.cF || 0)
          : (b.cF || 0) - (a.cF || 0);
      } else if (sortColumn === 'tarifHr') {
        return sortDirection === 'asc'
          ? (a.tarifHr || 0) - (b.tarifHr || 0)
          : (b.tarifHr || 0) - (a.tarifHr || 0);
      } else if (sortColumn === 'tarifRc') {
        return sortDirection === 'asc'
          ? (a.tarifRc || 0) - (b.tarifRc || 0)
          : (b.tarifRc || 0) - (a.tarifRc || 0);
      } else if (sortColumn === 'platformRC') {
        return sortDirection === 'asc'
          ? (a.platformRC || 0) - (b.platformRC || 0)
          : (b.platformRC || 0) - (a.platformRC || 0);
      } else if (sortColumn === 'platformHR') {
        return sortDirection === 'asc'
          ? (a.platformHR || 0) - (b.platformHR || 0)
          : (b.platformHR || 0) - (a.platformHR || 0);
      }
      return 0;
    });

    if (statusSortedItems !== null) {
      const sorted2 = [...statusSortedItems].sort((a, b) => {
        if (sortColumn === 'domain') {
          return sortDirection === 'asc'
            ? a.domain.localeCompare(b.domain)
            : b.domain.localeCompare(a.domain);
        } else if (sortColumn === 'languageId') {
          return sortDirection === 'asc'
            ? a.languageId - b.languageId
            : b.languageId - a.languageId;
        } else if (sortColumn === 'themesList') {
          return sortDirection === 'asc'
            ? (a.themesList || '').localeCompare(b.themesList || '')
            : (b.themesList || '').localeCompare(a.themesList || '');
        } else if (sortColumn === 'topicsList') {
          return sortDirection === 'asc'
            ? (a.topicsList || '').localeCompare(b.topicsList || '')
            : (b.topicsList || '').localeCompare(a.topicsList || '');
        } else if (sortColumn === 'contact') {
          return sortDirection === 'asc'
            ? (getEmailPart(a.contact) || '').localeCompare(
              getEmailPart(b.contact) || ''
            )
            : (getEmailPart(b.contact) || '').localeCompare(
              getEmailPart(a.contact) || ''
            );
        } else if (sortColumn === 'contactName') {
          return sortDirection === 'asc'
            ? (getNamePart(a.contact) || '').localeCompare(
              getNamePart(b.contact) || ''
            )
            : (getNamePart(b.contact) || '').localeCompare(
              getNamePart(a.contact) || ''
            );
        } else if (sortColumn === 'contactedAt') {
          return sortDirection === 'asc'
            ? (a.contactedTime || '').localeCompare(b.contactedTime || '')
            : (b.contactedTime || '').localeCompare(a.contactedTime || '');
        } else if (sortColumn === 'kwPosition') {
          return sortDirection === 'asc'
            ? (a.kwPositioned || 0) - (b.kwPositioned || 0)
            : (b.kwPositioned || 0) - (a.kwPositioned || 0);
        } else if (sortColumn === 'Trafic') {
          return sortDirection === 'asc'
            ? (a.trafic || 0) - (b.trafic || 0)
            : (b.trafic || 0) - (a.trafic || 0);
        } else if (sortColumn === 'tF') {
          return sortDirection === 'asc'
            ? (a.tF || 0) - (b.tF || 0)
            : (b.tF || 0) - (a.tF || 0);
        } else if (sortColumn === 'cF') {
          return sortDirection === 'asc'
            ? (a.cF || 0) - (b.cF || 0)
            : (b.cF || 0) - (a.cF || 0);
        } else if (sortColumn === 'tarifHr') {
          return sortDirection === 'asc'
            ? (a.tarifHr || 0) - (b.tarifHr || 0)
            : (b.tarifHr || 0) - (a.tarifHr || 0);
        } else if (sortColumn === 'tarifRc') {
          return sortDirection === 'asc'
            ? (a.tarifRc || 0) - (b.tarifRc || 0)
            : (b.tarifRc || 0) - (a.tarifRc || 0);
        } else if (sortColumn === 'platformRC') {
          return sortDirection === 'asc'
            ? (a.platformRC || 0) - (b.platformRC || 0)
            : (b.platformRC || 0) - (a.platformRC || 0);
        } else if (sortColumn === 'platformHR') {
          return sortDirection === 'asc'
            ? (a.platformHR || 0) - (b.platformHR || 0)
            : (b.platformHR || 0) - (a.platformHR || 0);
        }
        return 0;
      });
      setstatusSortedItems(sorted2);
    }

    setSortedItems(sorted);
  }, [
    shownNetlinkingFilteredList,
    mergedNetlinkings,
    sortColumn,
    sortDirection,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [nameSearch, selectedStatus]);

  useEffect(() => {
    let NetlinkingColorFilterArray = [];
    if (selectedStatus.includes(PIXLINK_STATUS.TO_DEFINE)) {
      NetlinkingColorFilterArray = [
        ...NetlinkingColorFilterArray,
        ...sortedItems.filter((itemA) => {
          return !netlinkingChosenData.some(
            (itemB) => itemB.netlinking.id === itemA.id
          );
        }),
      ];
    }

    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_VALIDATED_PIXALIONE)) {
      NetlinkingColorFilterArray = [
        ...netlinkingChosenData
          .filter(
            (obj) =>
              obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_ADMIN ||
              obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_SUPER_CONSULTANT
          )
          .map((item) => item.netlinking),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT)) {
      NetlinkingColorFilterArray = [
        ...netlinkingChosenData
          .filter(
            (obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT
          )
          .map((item) => item.netlinking),
        ,
        ...NetlinkingColorFilterArray,
      ];
    }

    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT)) {
      NetlinkingColorFilterArray = [
        ...netlinkingChosenData
          .filter(
            (obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT
          )
          .map((item) => item.netlinking),
        ,
        ...NetlinkingColorFilterArray,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.TO_BE_COMMANDED)) {
      NetlinkingColorFilterArray = [
        ...netlinkingChosenData
          .filter((obj) => obj.spotStatus === PIXLINK_STATUS.TO_BE_COMMANDED)
          .map((item) => item.netlinking),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.REFUSED_PIXALIONE)) {
      NetlinkingColorFilterArray = [
        ...netlinkingChosenData
          .filter((obj) => obj.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE)
          .map((item) => item.netlinking),
        ...NetlinkingColorFilterArray,
        ,
      ];
    }
    if (selectedStatus.includes(PIXLINK_STATUS.REFUSED_CLIENT)) {
      NetlinkingColorFilterArray = [
        ...netlinkingChosenData
          .filter((obj) => obj.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT)
          .map((item) => item.netlinking),
        ,
        ...NetlinkingColorFilterArray,
      ];
    }
    if (selectedStatus.includes('') && sortedItems.length > 0) {
      NetlinkingColorFilterArray = sortedItems;
    }
    let filteredArray = NetlinkingColorFilterArray.filter(
      (item) => item !== undefined
    );
    if (
      selectedStatus.length > 0 &&
      !(selectedStatus.length == 1 && selectedStatus.includes(''))
    ) {
      setstatusSortedItems(filteredArray);
    } else if (sortedItems.length > 0) {
      NetlinkingColorFilterArray = sortedItems;
      filteredArray = NetlinkingColorFilterArray.filter(
        (item) => item !== undefined
      );
      setstatusSortedItems(filteredArray);
    }
  }, [selectedStatus]);

  useEffect(() => {
    // Merge and filter the data
    const mergedData = mergeAndFilterNetlinkings(
      wantedNetlinkings,
      shownNetlinkingFilteredList
    );
    setMergedNetlinkings(mergedData);
  }, [wantedNetlinkings, shownNetlinkingFilteredList]);

  const mergeAndFilterNetlinkings = (
    wantedNetlinkings,
    existingNetlinkings
  ) => {
    // Combine the wanted netlinkings with the existing ones
    const combinedNetlinkings = [...wantedNetlinkings, ...existingNetlinkings];
    const mergedNetlinkings = combinedNetlinkings.filter((item, index, self) =>
      index === self.findIndex((t) => t.id === item.id)
    );
    return mergedNetlinkings;
  };

  const handleSortToggle = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const handleThemesListHover = (event, themes) => {
    event.target.textContent = themes;
  };

  const handleThemesListLeave = (event, themes) => {
    if (themes !== null && themes !== undefined && themes.includes(',')) {
      event.target.textContent = themes.split(',')[0] + '...';
    } else {
      event.target.textContent = themes;
    }
  };

  function netlinkingIsChosen(item) {
    if (
      campaignStatus ===
      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED ||
      campaignStatus ===
      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED ||
      campaignStatus ===
      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED ||
      campaignStatus ===
      PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED ||
      item.spotTmpStatus === PIXLINK_STATUS.REFUSED_PIXALIONE ||
      item.spotTmpStatus === PIXLINK_STATUS.REFUSED_CLIENT ||
      item.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE ||
      item.spotStatus === PIXLINK_STATUS.REFUSED_CLIENT
    ) {
      return true;
    } else {
      return netlinkingChosenData.some(
        (chosenItem) =>
          chosenItem.netlinking.id === item.id &&
          (chosenItem.spotStatus === PIXLINK_STATUS.REFUSED_PIXALIONE ||
            chosenItem.spotTmpStatus === PIXLINK_STATUS.REFUSED_PIXALIONE)
      );
    }
  }
  function netlinkingIsChosenColor(item) {

    // Check if the item is present in netlinkingChosenData

    const chosenItem = netlinkingChosenData.find(
      (chosenItem) => chosenItem.netlinking.id === item.id
    );
    if (chosenItem) {
      if ((item.type === 'redList' || item.type === 'liste rouge' || item.type === 'Liste rouge' || item.type === 'Liste Rouge') && !chosenItem.spotStatus) {
        return "RedList"
      }
      return chosenItem.spotStatus;
    }
    if (item.type === 'redList' || item.type === 'liste rouge' || item.type === 'Liste rouge' || item.type === 'Liste Rouge') {
      return "RedList"
    }
  }

  const transformDate = (dateString) => {
    const parsedDate = new Date(dateString);
    const year = parsedDate.getFullYear();
    const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2);
    const day = ('0' + parsedDate.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  };

  function getEmailPart(inputString) {
    try {
      // Split the inputString using '<' and '>' as delimiters
      const parts = inputString.split('<');

      // Check if the split produced at least two parts
      if (parts.length >= 2) {
        // Get the second part and remove the trailing '>'
        const emailPart = parts[1].replace('>', '').trim();
        return emailPart;
      } else {
        // If the inputString is not in the expected format, return null or an appropriate value
        return inputString;
      }
    } catch (err) {
      console.error('An error occurred:', err.message);
      return '-';
    }
  }

  function getNamePart(inputString) {
    try {
      // Split the inputString using '@' as a delimiter
      const emailRegex = /[\w.-]+@[\w.-]+\.[A-Za-z]{2,}/g;
      let stringWithoutEmail = inputString.replace(emailRegex, '');
      stringWithoutEmail = stringWithoutEmail.replace('>', '');
      stringWithoutEmail = stringWithoutEmail.replace('<', '');
      stringWithoutEmail = stringWithoutEmail.replace('/', '');
      if (stringWithoutEmail.trim().length === 0) {
        return '-';
      }
      return stringWithoutEmail.trim();
    } catch (err) {
      console.error('An error occurred:', err.message);
      return '-';
    }
  }

  const handleNetlinkingContactFilterChange = (value) => {
    // Call the callback function from the Father component to pass the value to the parent
    onNetlinkingContactFilterChange(value);
  };

  const handleCloseStatusFilter = () => {
    setAnchorEl(null);
  };
  const handleClickeStatusFilter = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget });
  };

  const history = useHistory();

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  console.log(statusSortedItems)
  const currentItems = sortColumn == "" ? (
    statusSortedItems !== null
      ? statusSortedItems
        .filter((item) =>
          item.domain.toLowerCase().includes(nameSearch.toLowerCase())
        ).sort((a, b) => a.domain.localeCompare(b.domain))
        .slice(indexOfFirstItem, indexOfLastItem)
      : sortedItems
        .filter((item) =>
          item.domain.toLowerCase().includes(nameSearch.toLowerCase())
        ).sort((a, b) => a.domain.localeCompare(b.domain))
        .slice(indexOfFirstItem, indexOfLastItem)) :
    (
      statusSortedItems !== null
        ? statusSortedItems
          .filter((item) =>
            item.domain.toLowerCase().includes(nameSearch.toLowerCase())
          )
          .slice(indexOfFirstItem, indexOfLastItem)
        : sortedItems
          .filter((item) =>
            item.domain.toLowerCase().includes(nameSearch.toLowerCase())
          )
          .slice(indexOfFirstItem, indexOfLastItem));

  return (
    <>
      <div className="table-responsive">
        <div className="box-table-netlinking">
          <table className="table-lists">
            <thead>
              <tr>
                {columns.find((column) => column.name === 'domain')
                  ?.visible && (
                    <th
                      className="cell-fixed-column"
                      onClick={() => handleSortToggle('domain')}
                    >
                      <span className={sortColumn === 'domain' ? 'active' : ''}>
                        {t('Domaine')}
                      </span>
                      {sortColumn === 'domain' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px', marginTop: '5px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px', marginTop: '5px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'themesList')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('themesList')}
                      className={sortColumn === 'themesList' ? 'active' : ''}
                    >
                      {t('Thématique')}
                      {sortColumn === 'themesList' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'topicsList')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('topicsList')}
                      className={sortColumn === 'topicsList' ? 'active' : ''}
                    >
                      {t('Topic Majestic')}
                      {sortColumn === 'topicsList' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'kwPositioned')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('kwPosition')}
                      className={sortColumn === 'kwPosition' ? 'active' : ''}
                    >
                      {t('Kw positionés')}
                      {sortColumn === 'kwPosition' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'traffic')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('Trafic')}
                      className={sortColumn === 'Trafic' ? 'active' : ''}
                    >
                      {t('Trafic')}{' '}
                      {sortColumn === 'Trafic' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'tF')?.visible && (
                  <th
                    onClick={() => handleSortToggle('tF')}
                    className={sortColumn === 'tF' ? 'active' : ''}
                  >
                    {t('TF')}{' '}
                    {sortColumn === 'tF' ? (
                      sortDirection === 'asc' ? (
                        <i
                          style={{ marginLeft: '3px' }}
                          className="bi bi-chevron-up"
                        />
                      ) : (
                        <i
                          style={{ marginLeft: '3px' }}
                          className="bi bi-chevron-down"
                        />
                      )
                    ) : null}
                  </th>
                )}
                {columns.find((column) => column.name === 'cF')?.visible && (
                  <th
                    onClick={() => handleSortToggle('cF')}
                    className={sortColumn === 'cF' ? 'active' : ''}
                  >
                    {t('CF')}{' '}
                    {sortColumn === 'cF' ? (
                      sortDirection === 'asc' ? (
                        <i
                          style={{ marginLeft: '3px' }}
                          className="bi bi-chevron-up"
                        />
                      ) : (
                        <i
                          style={{ marginLeft: '3px' }}
                          className="bi bi-chevron-down"
                        />
                      )
                    ) : null}
                  </th>
                )}
                {columns.find((column) => column.name === 'tarifHr')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('tarifHr')}
                      className={sortColumn === 'tarifHr' ? 'active' : ''}
                    >
                      {t('Tarif')} HR{' '}
                      {sortColumn === 'tarifHr' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'tarifRc')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('tarifRc')}
                      className={sortColumn === 'tarifRc' ? 'active' : ''}
                    >
                      {t('Tarif')} RC{' '}
                      {sortColumn === 'tarifRc' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'platformRC')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('platformRC')}
                      className={sortColumn === 'tarifRc' ? 'active' : ''}
                    >
                      {t('Tarif plateforme') + ' RC'}
                      {sortColumn === 'platformRC' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'platformHR')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('platformHR')}
                      className={sortColumn === 'tarifRc' ? 'active' : ''}
                    >
                      {t('Tarif plateforme') + ' HR'}
                      {sortColumn === 'platformHR' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}

                {columns.find((column) => column.name === 'contact')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('contact')}
                      className={sortColumn === 'contact' ? 'active' : ''}
                    >
                      {t('Contact')}
                      {sortColumn === 'contact' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                {columns.find((column) => column.name === 'contact')
                  ?.visible && (
                    <th
                      onClick={() => handleSortToggle('contactName')}
                      className={sortColumn === 'contactName' ? 'active' : ''}
                    >
                      {t('Nom contact')}
                      {sortColumn === 'contactName' ? (
                        sortDirection === 'asc' ? (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-up"
                          />
                        ) : (
                          <i
                            style={{ marginLeft: '3px' }}
                            className="bi bi-chevron-down"
                          />
                        )
                      ) : null}
                    </th>
                  )}
                <th>{t('Sélectionner')}</th>
                <th>
                  <CheckBox
                    name="thischeckbox"
                    tick={tableExport.length === sortedItems.length}
                    onCheck={() => {
                      if (tableExport.length < sortedItems.length) {
                        setTableExport([...sortedItems.map((item) => item)]);
                        sendNetlinkingExport([
                          ...sortedItems.map((item) => item),
                        ]);
                      } else {
                        setTableExport([]);
                        sendNetlinkingExport([]);
                      }
                    }}
                  ></CheckBox>{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id} style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (user.role === PIXLINK_ROLES.CONSULTANT) {
                      history.push(`/Consultant/Spot/Detail/${item.id}`);
                    } else if (user.role === PIXLINK_ROLES.SUPER_ADMIN) {
                      history.push(`/SuperAdmin/Spot/Detail/${item.id}`);
                    } else if (user.role === PIXLINK_ROLES.ADMIN) {
                      history.push(`/Admin/Spot/Detail/${item.id}`);
                    }
                  }}>
                  {columns.find((column) => column.name === 'domain')
                    ?.visible && (
                      <td className="cell-fixed-column" onClick={(e) => {
                        e.stopPropagation();
                      }}>
                        {isIdInArray(item.id, historicBackLinkings) && (
                          <span style={{ marginLeft: '5px' }}>
                            <img
                              style={{ width: '20px', cursor: 'pointer' }}
                              src="Images/icon-recyclage.png"
                              alt="icon-navigate"
                            />
                          </span>
                        )}
                        {isIdInArray(item.id, wantedNetlinkings) && (
                          <span style={{ marginLeft: '5px' }}>
                            <img
                              style={{
                                width: '15px',
                                cursor: 'pointer',
                                marginBottom: '5px',
                              }}
                              src="Images/icon-star.png"
                              alt="icon-navigate"
                            />
                          </span>
                        )}
                        <a
                          href={
                            !item.domain.startsWith('http://') &&
                              !item.domain.startsWith('https://')
                              ? 'https://' + item.domain
                              : item.domain
                          }
                          target="_blank"
                          className={(() => {
                            switch (netlinkingIsChosenColor(item)) {
                              case 'TO_BE_VALIDATED_SUPER_ADMIN':
                                return 'domain-container gras-grey-row';
                              case 'TO_BE_VALIDATED_SUPER_CONSULTANT':
                                return 'domain-container black-row';
                              case 'TO_BE_VALIDATED_CLIENT':
                                return 'domain-container orange-row';
                              case 'TO_BE_FILTERED_CONSULTANT':
                                return 'domain-container blue-row';
                              case 'TO_BE_COMMANDED':
                                return 'domain-container green-row';
                              case 'REFUSED_CLIENT':
                                return 'domain-container red-row';
                              case 'REFUSED_PIXALIONE':
                                return 'domain-container violet-row';
                              case 'RedList':
                                return 'domain-container gras-red-row'

                              default:
                                return 'domain-container grey-row'; // default class if netlinkingIsChosen(item) is neither true nor false
                            }
                          })()}
                        >
                          {item.domain}
                        </a>
                      </td>
                    )}
                  {columns.find((column) => column.name === 'themesList')
                    ?.visible && (
                      <td >
                        <span
                          onMouseOver={(event) =>
                            handleThemesListHover(event, item.themesList)
                          }
                          onMouseLeave={(event) =>
                            handleThemesListLeave(event, item.themesList)
                          }
                        >
                          {item.themesList && item.themesList.includes(',')
                            ? item.themesList.split(',')[0] + '...'
                            : item.themesList}
                        </span>
                      </td>
                    )}
                  {columns.find((column) => column.name === 'topicsList')
                    ?.visible && (
                      <td onClick={(e) => {
                      }}>
                        <span>{item.topicsList ? item.topicsList : '-'}</span>
                      </td>
                    )}{' '}
                  {columns.find((column) => column.name === 'kwPositioned')
                    ?.visible && (
                      <td onClick={(e) => {
                      }}>
                        <span>{item.kwPositioned ? item.kwPositioned : '-'}</span>
                      </td>
                    )}{' '}
                  {columns.find((column) => column.name === 'traffic')
                    ?.visible && (
                      <td onClick={(e) => {
                      }}>
                        <span>{item.trafic ? item.trafic : '-'}</span>
                      </td>
                    )}{' '}
                  {columns.find((column) => column.name === 'tF')?.visible && (
                    <td onClick={(e) => {
                    }}>
                      <span>{item.tF ? item.tF : '-'}</span>
                    </td>
                  )}{' '}
                  {columns.find((column) => column.name === 'cF')?.visible && (
                    <td onClick={(e) => {
                    }}>
                      <span>{item.cF ? item.cF : '-'}</span>
                    </td>
                  )}{' '}
                  {columns.find((column) => column.name === 'tarifHr')
                    ?.visible && (
                      <td onClick={(e) => {
                      }}>
                        <span
                          className={
                            (item.tarifHr && !item.tarifRc) ||
                              (item.tarifHr &&
                                item.tarifRc &&
                                item.tarifHr < item.tarifRc)
                              ? 'green-text'
                              : 'red-text'
                          }
                        >
                          {item.tarifHr ? item.tarifHr : '-'}
                        </span>
                      </td>
                    )}{' '}
                  {columns.find((column) => column.name === 'tarifRc')
                    ?.visible && (
                      <td onClick={(e) => {
                      }}>
                        <span
                          className={
                            (!item.tarifHr && item.tarifRc) ||
                              (item.tarifHr &&
                                item.tarifRc &&
                                item.tarifHr >= item.tarifRc)
                              ? 'green-text'
                              : 'red-text'
                          }
                        >
                          {item.tarifRc ? item.tarifRc : '-'}
                        </span>
                      </td>
                    )}{' '}
                  {columns.find((column) => column.name === 'platformRC')
                    ?.visible && (
                      <td onClick={(e) => {
                      }}>
                        {item.platformRC ? (
                          <CustomTooltipSmall title={item.platformName}>
                            <span>{item.platformRC}</span>
                          </CustomTooltipSmall>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                    )}
                  {columns.find((column) => column.name === 'platformHR')
                    ?.visible && (
                      <td onClick={() => { }}>
                        {item.platformHR ? (
                          <CustomTooltipSmall title={item.platformName}>
                            <span>{item.platformHR}</span>
                          </CustomTooltipSmall>
                        ) : (
                          <span>-</span>
                        )}
                      </td>
                    )}
                  {columns.find((column) => column.name === 'contact')
                    ?.visible && (
                      <td>
                        <span
                          onClick={(e) => {
                            handleNetlinkingContactFilterChange(
                              getEmailPart(item.contact)
                            );
                            e.stopPropagation();
                          }}
                          className={
                            contactFilter != '' &&
                              item.contact != null &&
                              item.contact != undefined &&
                              item.contact.includes(contactFilter)
                              ? 'yellow-text'
                              : ''
                          }
                        >
                          {item.contact ? getEmailPart(item.contact) : '-'}
                        </span>
                      </td>
                    )}{' '}
                  {columns.find((column) => column.name === 'contact')
                    ?.visible && (
                      <td onClick={() => { }}>
                        <span>
                          {item.contact ? getNamePart(item.contact) : '-'}
                        </span>
                      </td>
                    )}{' '}
                  <td
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <Switch
                      checked={tableChoose.some(
                        (netlinking) =>
                          netlinking.netlinking.id === item.id &&
                          netlinking.spotStatus !==
                          PIXLINK_STATUS.REFUSED_PIXALIONE
                      )}
                      disabled={(netlinkingIsChosen(item) && !reactivateSelection) || item.spotStatus ==
                        PIXLINK_STATUS.REFUSED_CLIENT || item.spotStatus ==
                        PIXLINK_STATUS.TO_BE_COMMANDED}
                      color="success"
                      onChange={(e) => {
                        if (
                          !tableChoose.some(
                            (netlinking) => netlinking.netlinking.id === item.id
                          )
                        ) {
                          setTableChoose([
                            ...tableChoose,
                            {
                              id: null,
                              responsibleRedactio: null,
                              responsiblePublication: null,
                              programmationDate: null,
                              publicationDate: null,
                              contactDate: null,
                              articleLink: null,
                              spotLink: null,
                              ancreLink: null,
                              insertionLink: null,
                              publicationStatus: null,
                              redactionStatus: null,
                              spotStatus: campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_STARTED_TO_BE_SELECTED_BY_CONSULTANT ? PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT :
                                campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_CONSULTANT_TO_BE_APPROVED ? PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT :
                                  campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_ADMIN_TO_BE_APPROVED ? PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT :
                                    campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_SUPER_ADMIN_SUPER_CONSULTANT_TO_BE_APPROVED ? PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT :
                                      campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_EDITOR_TO_BE_COMMANDED ? PIXLINK_STATUS.TO_BE_COMMANDED :
                                        campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CONSULTANT_TO_BE_FILTERED ? PIXLINK_STATUS.TO_BE_FILTERED_CONSULTANT :
                                          campaignStatus === PIXLINK_CAMPAIGN_STATUS.CAMPAIGN_NOTIFIED_CLIENT_TO_BE_VALIDATED ? PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT : PIXLINK_STATUS.TO_BE_VALIDATED_CLIENT,
                              netlinking: item,
                            },
                          ]);
                          sendNetlinkingChoose([...tableChoose, item]);
                        } else {
                          setTableChoose((tableChoose) =>
                            tableChoose.filter(
                              (netlinking) =>
                                netlinking.netlinking.id !== item.id
                            )
                          );
                          sendNetlinkingChoose(
                            tableChoose.filter(
                              (netlinking) =>
                                netlinking.netlinking.id !== item.id
                            )
                          );
                        }
                      }}
                    ></Switch>
                  </td>
                  <td onClick={(e) => {
                    e.stopPropagation();
                  }}>
                    <span onClick={(e) => e.stopPropagation()}>
                      <CheckBox
                        name="thischeckbox"
                        tick={tableExport.some(
                          (netlinking) => netlinking.id === item.id
                        )}
                        onCheck={() => {
                          if (
                            !tableExport.some(
                              (netlinking) => netlinking.id === item.id
                            )
                          ) {
                            setTableExport([...tableExport, item]);
                            sendNetlinkingExport([...tableExport, item]);
                          } else {
                            setTableExport((tableExport) =>
                              tableExport.filter(
                                (netlinking) => netlinking.id !== item.id
                              )
                            );
                            sendNetlinkingExport(
                              tableExport.filter(
                                (netlinking) => netlinking.id !== item.id
                              )
                            );
                          }
                        }}
                      ></CheckBox>{' '}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="box-pagination">
        {sortedItems.length === 0 || currentPage === 1 ? (
          <div style={{ cursor: 'not-allowed' }} className="text-page">
            {t('Précédent')}
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setCurrentPage(currentPage - 1)}
          >
            {t('Précédent')}
          </div>
        )}
        <div className="list-page-number">
          {sortedItems.length === 0 ||
            currentPage === Math.ceil(sortedItems.length / itemsPerPage) ? (
            <span style={{ cursor: 'not-allowed' }} className="text-page">
              {t('Suivant')}
            </span>
          ) : (
            <span onClick={() => setCurrentPage(currentPage + 1)}>
              {t('Suivant')}
            </span>
          )}
        </div>
      </div>
    </>
  );
}
